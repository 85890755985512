.container {
  width: 100%;
  height: 100vh;
}

.term-title {
  color: #285e82;
  font-size: 1.8rem;
  font-weight: 900;
}

.left-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5% 2%;
}

.right-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0% 10%;
}
