/* CustomSidebar.css */

.custom-sidebar-menu .ant-menu-item-selected {
  background-color: #333 !important;
  color: white !important;
}

.custom-sidebar-menu .ant-menu-item-selected a {
  color: white !important;
}

.custom-sidebar-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: #333 !important;
  color: white !important;
}

.custom-sidebar-menu .ant-menu-item:hover a {
  color: white !important;
}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color:#344054 
}